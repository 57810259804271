<template>
    <div class="onvo">
        <VideoBanner :data="videoData" class="onvo-video-banner">
            <div class="onvo-anchor"></div>
        </VideoBanner>
        <div class="manager">
            <p class="title">
                乐道是蔚来旗下的全新电动汽车品牌<br />以「让家庭生活更美好」为品牌使命
            </p>
            <div class="video-container">
                <video ref="videoRef" @play="isPlaying = true" @ended="
            $refs.videoRef.controls = false;
        isPlaying = false;
        $refs.videoRef.currentTime = 0;
        " src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道校招采访.mp4" muted preload></video>
                <div class="video-cover" v-show="!isPlaying">
                    <div class="video-button" @click="
            $refs.videoRef.controls = true;
        $refs.videoRef.play();
        "></div>
                    <p>点击查看乐道总裁给校招生的寄语</p>
                </div>
            </div>
        </div>
        <p class="title">启程，和乐道一起走「向上的职业之路」</p>
        <img class="direction-image"
            src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道职业方向.png?imageView2/interlace/1" />
        <img class="recommend-image"
            src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道招聘推荐.png?imageView2/interlace/1" />
        <p class="title">我们正在招</p>
        <div class="directions">
            <div class="card-item" v-for="(item, index) in recruitPositions" :key="index">
                <img :src="item.back" />
                <div class="card-bottom">
                    <p>{{ item.title }}</p>
                    <p class="card-subtitle">
                        {{ item.desc }}
                        <a class="deliver-button" :href="item.deliverUrl" target="_blank">立即投递 <span>></span></a>
                    </p>
                </div>
            </div>
        </div>
        <p class="title">在这里，乐在其中</p>
        <p class="subtitle">收获快乐和成长的无限可能</p>
        <el-carousel ref="nop" :interval="4000" :loop="true" type="card" indicator-position="none" :autoplay="true"
            arrow="never" height="280px" class="onvo-swiper">
            <el-carousel-item class="swiper-item" v-for="(item, index) in swpierList" :key="index">
                <img :src="item" />
            </el-carousel-item>
        </el-carousel>
        <p class="title">乐道Sparks职业发展路径</p>
        <p class="subtitle">
            战略级人才发展项目<br />
            总部、地区双重关注<br />
            优秀Sparks可实现“一年主管，两年店总”的超高速发展
        </p>
        <div class="development-image">
            <img src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道Sparks职业发展路径.png?imageView2/interlace/1" />
        </div>
        <div class="onvo-recruit">
            <p class="title">招聘流程</p>
            <img src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道招聘流程.png?imageView2/interlace/1" />
        </div>
        <!--城市选择-->
        <div class="city">
            <p class="title">超多城市选择，总有你向往的那一个</p>
            <p class="subtitle">职位覆盖全国，点击了解更多职位信息</p>
            <div class="cityButton">
                <el-button class="button_list" v-for="(item, k) in cityList" :key="k" @click="getDelivery(item.linkUrl)"
                    plain>{{ item.cityName }}</el-button>
            </div>
            <a class="more-city"
                href="https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list="
                target="_blank">点击查看更多选择<span>></span></a>
        </div>
    </div>
</template>
<script>
import VideoBanner from "../../components/VideoBanner.vue";
import { onvoRecruitPositions, onvoSwipeList } from "@/utils/config.js";
export default {
    name: "OnvoRegion",
    components: { VideoBanner },
    data() {
        return {
            isPlaying: false,
            videoData: {
                videoUrl:
                    "https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/乐道校招官网视频（短）.mp4",
                title: "乐道区域公司校园招聘",
                subtitle: "乐在其中 大有可为",
                deliverUrl:
                    "https://nio.jobs.feishu.cn/campus/?keywords=%E4%B9%90%E9%81%93%E9%A1%BE%E9%97%AE&category=&location=&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list=",
                deliverButtonStyle: {
                    borderRadius: "24px",
                },
            },
            recruitPositions: onvoRecruitPositions,
            swpierList: onvoSwipeList,
            cityList: [
                {
                    cityName: "北京",
                    linkUrl:
                        "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_11&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list=",
                },
                {
                    cityName: "上海",
                    linkUrl:
                        "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_125&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list=",
                },
                {
                    cityName: "广州",
                    linkUrl:
                        "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_45&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list=",
                },
                {
                    cityName: "深圳",
                    linkUrl:
                        "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_128&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list=",
                },
                {
                    cityName: "杭州",
                    linkUrl:
                        "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_52&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list=",
                },
                {
                    cityName: "合肥",
                    linkUrl:
                        "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_55&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list=",
                },
                {
                    cityName: "武汉",
                    linkUrl:
                        "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_154&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list=",
                },
                {
                    cityName: "天津",
                    linkUrl:
                        "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_143&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list=",
                },
                {
                    cityName: "青岛",
                    linkUrl:
                        "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_119&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list=",
                },
                {
                    cityName: "成都",
                    linkUrl:
                        "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_22&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list=",
                },
                {
                    cityName: "苏州",
                    linkUrl:
                        "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_199&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list=",
                },
                {
                    cityName: "南京",
                    linkUrl:
                        "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_107&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list=",
                },
                {
                    cityName: "郑州",
                    linkUrl:
                        "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_188&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list=",
                },
                {
                    cityName: "西安",
                    linkUrl:
                        "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_155&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list=",
                },
                {
                    cityName: "沈阳",
                    linkUrl:
                        "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_129&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list=",
                },
                {
                    cityName: "重庆",
                    linkUrl:
                        "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_190&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352906034153755&storefront_id_list=",
                },
            ],
        };
    },
    methods: {
        //跳转
        getDelivery(data) {
            window.open(data);
        },
    },
};
</script>
<style>
.video-container {
    width: min(70%, 800px);
}

.direction-image {
    width: min(1000px, 80%);
}

.recommend-image {
    width: min(1200px, 100%);
    margin: 0 auto;
}

.development-image {
    width: min(1000px, 80%);
}

.onvo-swiper {
    width: min(800px, 70%);
}

.onvo-recruit {
    width: min(1000px, 80%);
}
</style>
<style lang="less" scoped>
.onvo {
    font-family: "ONVOFJHLight";
    min-width: 1200px;
    background-color: #fff6ef;
    text-align: center;
    line-height: 2;
    color: #000;
}

.onvo-anchor {
    position: relative;
    width: 47px;
    height: 23px;
    cursor: pointer;

    &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 11px;
        transform: rotateZ(-45deg);
        width: 5px;
        height: 32px;
        background-color: #fff;
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        right: 11px;
        transform: rotateZ(45deg);
        width: 5px;
        height: 32px;
        background-color: #fff;
    }
}

.onvo-video-banner {
    /deep/ .content {
        .title {
            font-family: "ONVOFJHRegular";
        }

        .deliver-button:hover {
            background-color: #ff2f01;
            border-color: #ff2f01;
        }
    }
}

.title {
    font-size: 36px;
    font-weight: 600;
    padding: 92px 0 50px;
}

.title+.subtitle {
    margin-top: -50px;
    font-size: 20px;
    font-weight: 600;
}

.manager {
    background-image: url("../../assets/img/onvo/icon.svg");
    background-color: #ffefe0;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: soft-light;
    background-position: center;
    padding: 64px 0;

    .title {
        padding-top: 69px;
        padding-bottom: 12px;
    }
}

.video-container {
    margin: 12px auto 40px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;

    video {
        display: block;
        width: 100%;
        height: 100%;
    }

    .video-cover {
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        font-size: 24px;
        border-radius: 20px;
        color: #fff;
        text-align: center;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
    }

    .video-button {
        border-radius: 50%;
        border: 3px solid #f9390e;
        position: relative;
        width: 100px;
        height: 100px;
        box-sizing: border-box;
        cursor: pointer;

        &+p {
            margin-top: 16px;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 5px;
            box-sizing: border-box;
            display: block;
            border-radius: 50%;
            border: 1px solid #f9390e;
        }

        &::after {
            content: "";
            display: block;
            background-image: url("~@/assets/img/onvo-video-button.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 30px;
            height: 45px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin-left: 5px;
        }
    }
}

.direction-image {
    margin: 26px 10% 185px;
}

.directions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 160px;
}

.card-item {
    text-align: left;
    width: 400px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px #00000026;
    margin-right: 80px;
    font-size: 20px;
    overflow: hidden;

    &:last-of-type {
        margin-right: 0;
    }

    img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        object-position: center;
    }
}

.card-bottom {
    padding: 20px 40px;
}

.card-subtitle {
    font-size: 14px;
}

.deliver-button {
    color: #ff2e00;
    float: right;
    font-size: 14px;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;

    span {
        font-size: 18px;
        margin-left: 0.3em;
        line-height: 26px;
    }
}

.onvo-swiper {
    margin: 153px auto 195px;

    .swiper-item {
        border-radius: 20px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

.development-image {
    background-color: #ffefe1;
    border-radius: 20px;
    padding: 5px;
    margin: 23px auto 73px;

    img {
        width: 100%;
        object-fit: contain;
        object-position: center;
    }
}

.onvo-recruit {
    margin: 50px auto;
    background-color: #ffefe1;
    border-radius: 20px;

    .title {
        padding-top: 30px;
    }

    img {
        width: 70%;
        margin-bottom: 100px;
    }
}

.city {
    width: 1000px;
    text-align: center;
    padding-bottom: 60px;
    margin: 0 auto;

    .cityButton {
        margin-top: 50px;
        display: grid;
        grid-template-columns: repeat(4, minmax(150px, 1fr));
        justify-content: space-between;
        row-gap: 30px;
        column-gap: 60px;

        .button_list {
            display: inline-block;
            width: 100%;
            height: 44px;
            color: #040b29;
            border: none;
            font-size: 16px;
            margin: 0;
            background-color: #ffefe1;
            border-radius: 15px;
        }

        .button_list:hover {
            background: #ff2f01;
            overflow: hidden;
            color: #ffffff;
        }
    }

    .more-city {
        display: block;
        font-size: 16px;
        color: #ff2e00;
        text-align: right;
        white-space: nowrap;
        margin-top: 72px;

        span {
            margin-left: 1em;
        }
    }
}
</style>
